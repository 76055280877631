<template>
  <div class="detail">
    <div class="edit_log" v-if="$route.query && $route.query.id" @click="logDrawerVisible=true">
    <i class="el-icon-warning-outline"></i>
    <span class="mt-1">动态信息</span>
    </div>
    <content-block title="基本信息">
      <el-form
        :model="form"
        label-width="160px"
        :disabled="type === 'detail'"
        :rules="rules"
        label-position='right'
        ref="customerForm"
      >
        <el-row>
          <el-col :span="8">
            <el-form-item label="医院名称：" prop="name">
              <hospital-selector v-model="form.originId"
                :name.sync="form.name"
                placeholder="请输入医院名称"
                :readonly="type === 'edit' && form.status === 'EFFECTIVE'"
                @change="handleHospitalSelect">
              </hospital-selector>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="统一社会信用代码：">
              <el-input
                v-model="form.socialCode"
                disabled
                placeholder="请输入统一社会信用代码"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="医院状态：" prop="status">
              <el-select v-model="form.status" :disabled="type === 'edit' && form.status === 'EFFECTIVE' && form.isAssociation" placeholder="请选择医院状态">
                <el-option
                  v-for="item in statusOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="所在地区：" prop="areaId">
              <area-cascader
                :readonly="type === 'edit' && form.status === 'EFFECTIVE' && form.isAssociation"
                :provinceId.sync="form.provinceId"
                :provinceName.sync="form.provinceName"
                :cityId.sync="form.cityId"
                :cityName.sync="form.cityName"
                :areaId.sync="form.areaId"
                :areaName.sync="form.areaName"
                @handleValidate='handleValidate'
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="详细地址：" prop="address">
              <el-input
                :disabled="type === 'edit' && form.status === 'EFFECTIVE' && form.isAssociation"
                v-model="form.address"
                placeholder="请输入详细地址"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="医院座机：">
              <el-input
                v-model="form.tel"
                :maxlength="20"
                placeholder="请输入医院座机"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="医院传真：">
              <el-input
                v-model="form.fax"
                :maxlength="20"
                placeholder="请输入医院传真"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="医院邮箱：" prop="email">
              <el-input
                v-model="form.email"
                :maxlength="50"
                placeholder="请输入医院邮箱"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="备注：">
              <el-input
                v-model="form.remark"
                :maxlength="200"
                placeholder="请输入备注"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24" v-if="type === 'add'">
            <el-form-item label="">
            <el-button type="primary" plain @click="back">{{ type !== 'detail'? '取消' : '返回'}}</el-button>
            <el-button v-if="type !=='detail'" type="primary" @click="save"
              >保存</el-button
            >
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </content-block>
    <template v-if="form.coordinationStatus !== ''" >
      <content-block :style="{marginTop: 0}" title="协同信息">
        <el-form
          :model="form"
          label-width="160px"
          :disabled="type === 'detail'"
          label-position='right'
        >
          <el-row>
            <el-col :span="8">
              <el-form-item label="医院状态：" prop="coordinationStatus">
                <el-select v-model="form.coordinationStatus" disabled placeholder="请选择协同状态">
                  <el-option
                    v-for="item in synergyOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </content-block>
    </template>
    <template v-if="type !== 'add'">
      <content-block>
        <el-tabs
          v-if="type !== 'add'"
          v-model="activeName"
        >
          <el-tab-pane label="联系人信息" name="contact">
            <contact-table :customerInfo="customerInfo" />
          </el-tab-pane>
          <el-tab-pane label="产品信息" name="product">
            <device-table :customerInfo="customerInfo" />
          </el-tab-pane>
        </el-tabs>
      </content-block>
    </template>
    <BottomPanel  v-if="type !== 'add'">
      <slot>
        <el-button type="primary" plain @click="back">{{ type !== 'detail'? '取消' : '返回'}}</el-button>
        <el-button v-if="type !=='detail'" type="primary" @click="save"
          >保存</el-button
        >
      </slot>
    </BottomPanel>
    <el-drawer
      custom-class="operation_drawer"
      title="动态信息"
      :visible.sync="logDrawerVisible"
      direction="rtl">
      <div class="pl-2 pt-2 pr-2 pb-2">
        <el-timeline v-if="form.dynamicInformList && form.dynamicInformList[0]">
          <el-timeline-item
            v-for="(activity, index) in form.dynamicInformList"
            :key="index"
            placement="top"
            type="primary"
            :timestamp="activity.operateTime | date-format">
              <div>
                <div>{{activity.actionDesc}}</div>
                <div>{{activity.content}}</div>
              </div>
          </el-timeline-item>
        </el-timeline>
        <el-empty v-else></el-empty>
      </div>
    </el-drawer>
  </div>
</template>
<script>
import CustomerApi from '@/api/customer';
import ContactTable from './components/ContactTable.vue';
import DeviceTable from './components/DeviceTable.vue';
import AreaCascader from '@/components/common/areaCascader';
import HospitalSelector from '@/components/hospitalSelector';

export default {
  name: 'CUSTOMER_DETAIL',
  components: {
    ContactTable,
    DeviceTable,
    AreaCascader,
    HospitalSelector,
  },
  data () {
    const checkEmail = (rule, value, cb) => {
      const regEmail = /^(?=.*@)(?=.*\.)[^@.].*[^@.]$/;
      if (['', null].includes(value) || regEmail.test(value)) {
        return cb();
      }
      cb(new Error('请输入合法的邮箱'));
    };

    return {
      type: this.$route.query.type,
      activeName: 'contact',
      form: {
        address: '',
        areaId: '',
        areaName: '',
        cityId: '',
        cityName: '',
        email: '',
        fax: '',
        name: '',
        provinceId: '',
        provinceName: '',
        remark: '',
        status: 'EFFECTIVE',
        tel: '',
        originId: '',
        socialCode: '',
        coordinationStatus: '',
      },
      synergyOptions: [
        {
          value: 'ENABLED',
          label: '已启用',
        },
        {
          value: 'NOTENABLED',
          label: '未启用',
        },
      ],
      customerInfo: {
        customerId: null,
        customerName: null,
      },
      rules: {
        name: [{ required: true, message: '请输入医院名称', trigger: 'blur' }],
        areaId: [{ required: true, message: '请选择地址', trigger: 'blur' }],
        address: [
          { required: true, message: '请输入详细地址', trigger: 'blur' },
        ],
        status: [
          { required: true, message: '请选择医院状态', trigger: 'change' },
        ],
        email: [
          {validator: checkEmail, trigger: 'blur'},
        ],
      },
      statusOptions: [
        {
          value: 'EFFECTIVE',
          label: '有效',
        },
        {
          value: 'INVALID',
          label: '无效',
        },
        {
          value: 'DRAFT',
          label: '草稿',
        },
      ],
      logDrawerVisible: false,
    };
  },
  methods: {
    // TODO 请求判断结果
    handleValidate () {
      this.$refs.customerForm.clearValidate('areaId');
    },
    handleHospitalSelect (item) {
      this.form.socialCode = item.enterpriseCertCode;
      // TODO 移除判断条件
      this.$refs.customerForm.clearValidate('name');
    },
    getDetail () {
      CustomerApi.getCustomerDetail(this.$route.query.id).then(
        (res) => {
          this.form = res.body;

          const { name: customerName, id: customerId } = res.body;
          this.customerInfo.customerId = customerId;
          this.customerInfo.customerName = customerName;
        },
      );
    },
    back (refresh = false) {
      this.$tabs.close({
        to: '/customer/list',
        refresh,
      });
    },
    save () {
      this.$refs.customerForm.validate((valid) => {
        if (valid) {
          const api = this.type === 'add' ? CustomerApi.addCustomer : CustomerApi.updateCustomer;

          api(this.form).then(() => {
            const msg = this.type === 'add' ? '新增成功' : '修改成功';

            this.$message({
              message: msg,
              type: 'success',
            });

            /*
            if (this.type === 'add') {
              this.$eventBus.$emit('openPaymentDialogManually', {
                defaultCheckedId: res.body.id,
                defaultCheckedName: res.body.name,
              });
            }
            */

            this.back(true);
          });
        }
      });
    },
  },
  created () {
    if (this.type !== 'add') {
      this.getDetail();
    }
  },
};
</script>

<style lang="scss" scoped>
.detail {
  position: relative;
  height: 100%;
  .edit_log {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    width: 26px;
    height: 108px;
    font-size: 12px;
    line-height: 16px;
    color: #FFFFFF;
    padding: 10px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    writing-mode: vertical-lr;
    z-index: 1000;
    cursor: pointer;
    background: #6FB8FF;
    border-radius: 8px 0px 0px 8px;
    &:hover {
      background: #0F89FF;;
    }

    &>span {
      letter-spacing: 4px;
    }
  }

  /deep/ .operation_drawer {
    min-width: 336px;
  }
  /deep/.el-form-item {
    margin-bottom: 12px !important;
  }
}
</style>
