<template>
  <div>
    <table-with-pagination
      :tableData="tableData"
      :columnList="columnList"
      :pagination.sync="pagination"
      :sortInfo.sync="updateSortInfo"
      @current-change="getTableList"
    />
  </div>
</template>
<script>
import ContactApi from '@/api/contact';
import { TablePaginationMixin } from '@/components/common/TableWithPagination';

export default {
  name: 'CONTACT_TABLE',
  mixins: [TablePaginationMixin],
  props: {
    customerInfo: {
      type: Object,
      default: () => {},
    },
  },
  watch: {
    customerInfo: {
      handler () {
        this.getTableList();
      },
      deep: true,
    },
  },
  data () {
    return {
      tableData: [],
      columnList: [
        {
          tooltip: true,
          label: '姓名',
          minWidth: '160px',
          prop: 'name',
        },
        {
          tooltip: true,
          label: '性别',
          minWidth: '150px',
          prop: 'gender',
          render: (h, { row }) => {
            const content = row.gender ? (row.gender === 'WOMAN' ? '女' : '男') : '--';
            return h('span', content);
          },
        },
        {
          tooltip: true,
          label: '手机号',
          minWidth: '150px',
          prop: 'phone',
        },
        {
          tooltip: true,
          label: '部门',
          minWidth: '150px',
          prop: 'department',
        },
        {
          tooltip: true,
          label: '职位',
          minWidth: '150px',
          prop: 'position',
        },
        {
          tooltip: true,
          label: '固话',
          minWidth: '150px',
          prop: 'tel',
        },
        {
          tooltip: true,
          label: '是否默认联系人',
          minWidth: '150px',
          prop: 'isDefault',
          render: (h, { row }) => {
            const content = row.isDefault ? '是' : '否';
            return h('span', content);
          },
        },
        {
          tooltip: true,
          label: '备注',
          minWidth: '150px',
          prop: 'remark',
        },
      ],
    };
  },
  methods: {
    getTableList () {
      if (!this.customerInfo.customerId) {
        return;
      }

      ContactApi.getContactList({
        ...this.customerInfo,
        customerStatus: 'EFFECTIVE',
        ...this.pagination,
        pageNum: this.pagination.currentPage,
      }).then((res) => {
        this.tableData = res.body.records ?? [];
        this.pagination.total = res.body.total;
      });
    },
  },
};
</script>
